import { useEffect, useState } from 'react';
import moment from "moment";
import { useFetch, useIsLoading } from '../../Hooks/useFetch';

import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import { getAllCertificates, updateCertificateById, deleteCertificate } from "../../Service/InventoryService/InventoryService"; 

import { ListEditItems } from '../../Components/ListEditItems/ListEditItems';

import "../../Components/Static/Iventory/inventory.css";

export const  Certificates = () => {
  const [ certificates, isFetchingCertificates, updateCertificates ] = useFetch(getAllCertificates)
  const isLoading = useIsLoading([isFetchingCertificates]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "expiration_day",
      headerName: "Fecha de caducidad",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
      editable: true,
    },
    {
      field: "type",
      headerName: "Tipo de certificado",
      width: 300,
      flex: 2,
      editable: true,
    },
    {
      field: "url",
      headerName: "URL",
      width: 300,
      flex: 2,
      editable: true,
    },
    {
      field: "hosting",
      headerName: "Hosting",
      width: 300,
      flex: 1,
      editable: true,
    },
    
    {
      field: "auto_re",
      headerName: "Autorenovación",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{ params.value ? 'Si' : 'No'}</>;
      },
      editable: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-5' id='home'>
        <h4 className='main-title mb-3 mt-0'>Certificados</h4>

        <ListEditItems 
          rows={certificates} 
          columns={columns}
          serviceUpdate={updateCertificateById}
          serviceDelete={deleteCertificate}
          itemType={'certificado'}
          update={() => updateCertificates()}
        />
      </div>
    </div>
  )
}
