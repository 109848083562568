import { useEffect, useState } from 'react';
import { Formik, useFormik } from "formik";

import { toast } from "react-toastify";
import * as yup from "yup";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";


import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useFetch, useIsLoading } from '../../Hooks/useFetch';

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import { getAllAccessType } from "../../Service/AccessTypeService/AccessTypeService";
import { getAllHost } from "../../Service/HostService/HostService";
import { getAllParther } from "../../Service/PartherService/PartherService";
import { getAllLicenceTypeService } from "../../Service/LicenceTypeService/LicenceTypeService";
import { addItemToInventory, getInventoryItem, updateInventory, addItemToCertificates, addItemToDomains } from "../../Service/InventoryService/InventoryService";

const validationSchema = yup.object({
  app_name: yup.string().required("Este campo es requerido"),
  description: yup.string().required("Este campo es requerido"),
  id_access: yup.object().required("Este campo es requerido"),
});

export const NewItem = (props: any) => {
	const history = useHistory();
	const params: any = useParams();

	const [ selectedRow, isFetchingInventaryItem ] = useFetch(getInventoryItem, params)
  const [ access, isFetchingAccess ] = useFetch(getAllAccessType);
  const [ partner, isFetchingpartners ] = useFetch(getAllParther);
  const [ host, isFetchingHost ] = useFetch(getAllHost);
  const [ licence, isFetchingLicence ] = useFetch(getAllLicenceTypeService);

	const isLoading = useIsLoading([
		isFetchingInventaryItem,
    isFetchingAccess,
		isFetchingpartners,
		isFetchingHost,
		isFetchingLicence
  ]);

  const [ files, setFiles ] = useState<any[]>([]);
  const [ filesName, setFilesNames ] = useState<any[]>([]);

  const [ IsLoadingButtom, setIsLoadingButtom ] = useState(false);

	const [ partnerOptions, setPartnerOptions ] = useState<any[]>([]);
	const [ makerOptions, setMakerOptions ] = useState<any[]>([]);

	const newItemOptions = ['Licencia', 'Activo tecnológico', 'Dominio', 'Certificado'];
	const itemType = window.location.pathname.includes('activo') ? newItemOptions[1] : window.location.pathname.includes('licencia') ? newItemOptions[0] : undefined;  

	const levelOptions = [
		{	
			id: 0,
			level: "Baja",
		},
		{	
			id: 1,
			level: "Moderada/Media",
		},
		{	
			id: 2,
			level: "Alta",
		},
	];
	const confidOptions = [
		{	
			id: 0,
			level: "Pública",
		},
		{	
			id: 1,
			level: "Interna",
		},
		{	
			id: 2,
			level: "Confidencial",
		},
		{	
			id: 3,
			level: "Reservada",
		},
		{	
			id: 4,
			level: "Secreta",
		}	
	];

	useEffect(() => {
		const sorter = (a: any, b: any) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0;

		if (partner) {
			setPartnerOptions(partner.filter((item: any) => item.is_part === true && item.is_part != null).sort(sorter));
			setMakerOptions(partner.filter((item: any) => item.is_maker === true && item.is_maker != null).sort(sorter));
		}
	}, [partner])

  const formik = useFormik({
    initialValues: {
			item_type: props.edit ? itemType : props.dominio ? newItemOptions[2] : props.certificado ? newItemOptions[3] : null,
      app_name: props.edit ? selectedRow?.app_name : null,
      description: props.edit ? selectedRow?.description : null,
      website: props.edit ? selectedRow?.website : null,
      id_access: props.edit ? selectedRow?.id_access && access ? access.find((a: any) => a.id === selectedRow?.id_access) : null : null,
			maker: props.edit ? selectedRow?.maker && partner ? partner.find((a: any) => a.id === selectedRow?.maker) : null : null,
			id_partner: props.edit ? selectedRow?.id_partner && partner ? partner.find((a: any) => a.id === selectedRow?.id_partner) : null : null,
			id_host: props.edit ? selectedRow?.id_host && host ? host.find((a: any) => a.id === selectedRow?.id_host) : null : null,
			id_licence: props.edit ? selectedRow ?.id_licence && licence ? licence.find((a: any) => a.id === selectedRow?.id_licence) : null : null,
      purchase_date: props.edit ? selectedRow?.purchase_date : null,
      accesses_number: props.edit ? selectedRow?.accesses_number : null,
      consumed_users: props.edit ? selectedRow?.consumed_users : null,
      licence_number: props.edit ? selectedRow?.licence_number : null,
      licenses_files: null,
			licenses_path: null,
      email: props.edit ? selectedRow?.email : null,
			status: props.edit ? selectedRow?.status : true,
			crits: props.edit ? selectedRow?.crits : 0,
			integrity: props.edit ? selectedRow?.integrity : 0,
			dispo: props.edit ? selectedRow?.dispo : 0,
			confid: props.edit ? selectedRow?.confid : 0,
			departmen: props.edit ? selectedRow?.departmen : null,
			product_own: props.edit ? selectedRow?.product_own : null,
			buss_own: props.edit ? selectedRow?.buss_own : null,
			advise_days: props.edit ? selectedRow?.advise_days : 0,
      budget: props.edit ? selectedRow?.budget : null,
      comment: props.edit ? selectedRow?.comment : null,
      supplier: props.edit ? selectedRow?.suppier : null,
    //   expiration_day: props.edit && selectedRow.expiration_day ? moment(selectedRow?.expiration_day).format('YYYY-MM-DD') : null,
      expiration_day: props.edit ? moment(selectedRow?.expiration_day).format('YYYY-MM-DD') : null,
			type: null,
			url: null,
			hosting: null,
			emit: null,
			cost_re: null,
			auto_re: true,	
      active: true,
			files: null,
		},

    enableReinitialize: true,
    validationSchema: itemType ? validationSchema : null,
    
		onSubmit: async (values: any) => {		
			const filesToSend = files.map((file64: string) => {
				return file64.replace("data:", "").replace(/^.+,/, "");
      });
					
      setIsLoadingButtom(true);
     
			const body = {
				inventory : {
					...values, 
					id: props.edit ? params?.id : null, 
					id_access: values.id_access?.id,
					id_host: values.id_host?.id,
					id_licence: values.id_licence?.id,
					id_partner: values.id_partner?.id,
					activo: values.item_type === 'Activo tecnológico' ? true : false,
					req_licence: values.item_type === 'Licencia' ? true : false,
					expiration_day: values.expiration_day ? values.expiration_day + 'T00:00' : null,
					emit: values.emit ? values.emit + 'T00:00' : null,
					crits: values.crits ? values.crits?.id : null,
					integrity: values.integrity ? values.integrity?.id : null,
					dispo: values.dispo ? values.dispo?.id : null,
					confid: values.confid ? values.confid?.id : null,
					maker: values.maker?.id,		
				}, 
				licenses_files: filesToSend,
				licenses_names: filesName,
			}
     
			if ( values.item_type === 'Activo tecnológico' || values.item_type === 'Licencia' ) {
					if ( props.edit ) {
						updateInventory(body, body.inventory.id)
							.then(() => toast.success("Guardado"))
							.then(() => history.push(`${values.item_type === 'Activo tecnológico' ? '/activos-tecnologicos' : '/'}`))
					} else {
						addItemToInventory(body)
							.then(() => toast.success("Guardado"))
							.then(() => history.push(`${values.item_type === 'Activo tecnológico' ? '/activos-tecnologicos' : '/'}`)
							)
					}
			}

			if ( values.item_type === 'Dominio' ) {
				addItemToDomains(body.inventory)
				.then(() => toast.success("Guardado"))
				.then(() => history.push('/dominios'))
			}

			if ( values.item_type === 'Certificado' ) {
				addItemToCertificates(body.inventory)
				.then(() => toast.success("Guardado"))
				.then(() => history.push('/certificados'))
			}
    },
  });
    
  console.log(selectedRow);
  
  const handleUploadFiles = (files: any, event: any) => {
    let filesUploadedList = event.currentTarget.files;

    let fileListBase64: any[] = [];
		let fileNameList: any[] = [];

    Object.values(filesUploadedList).forEach((file: any) => {
			fileNameList.push(file.name)

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileBase64 = reader.result?.toString() || "";
        fileListBase64.push(fileBase64);
      };
    });

    formik.handleChange(event);
		setFilesNames(fileNameList)
    setFiles(fileListBase64);
  };

	const handleActiveOptions = () => {
    formik.setFieldValue('status', !formik.values.status);
  }


  if (isLoading && isFetchingInventaryItem ) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading />
      </div>
    );
  }

  return (
		<>
		<div className='h-100 m-4'>
      <div className='main-container p-3' id='home'>
        <h4 className='main-title mb-3'>{ props.edit ? 'Editar' : 'Añadir' } {formik.values.item_type} </h4>
            
				<Box
					component="form"
					sx={{
						width: "90%",
						mx: "auto",
						my: 2,
						display: "flex",
						flexDirection: "column",
						alignItems: 'stretch'
					}}
					noValidate
					autoComplete="on"
					className="text-center"
					onSubmit={formik.handleSubmit}
					>
				{ props.edit ? <></> : (			
					<Autocomplete
						id="item_type"
						style={{ margin: "1rem 0", width: "50%"}}
						options={newItemOptions}
						onChange={(e, value) => {
							formik.setFieldValue("item_type", value);
						}}
						value={formik.values.item_type}
						renderInput={(params) => (
							<TextField
							{...params}
							label="Nuevo ítem"
								variant="outlined"
								error={
									formik.touched.item_type && Boolean(formik.errors.item_type)
								}
								helperText={formik.touched.item_type && formik.errors.item_type} 
							/>
						)}
					/>
				)}	

				{ formik.values.item_type === 'Activo tecnológico' || formik.values.item_type === 'Licencia' ? (
					<>					
						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField
								required
								id="app_name"
								name="app_name"
								style={{ width: "50%", margin: "1rem 0", flexGrow: "2"}}
								label="Nombre"
								value={formik.values.app_name}
								onChange={formik.handleChange}
								error={formik.touched.app_name && Boolean(formik.errors.app_name)}
								helperText={formik.touched.app_name && formik.errors.app_name}
							/>

							<TextField 
								required
								id="description"
								name="description"
								style={{ width: "50%", margin: "1rem 0 1rem 1rem", flexGrow: "1"}}
								label="Descripción"
								value={formik.values.description}
								onChange={formik.handleChange}
								error={
									formik.touched.description && Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description && formik.errors.description
								}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField      
								id="website"
								name="website"
								label="Web"
								style={{ width: "35%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
								value={formik.values.website}
								onChange={formik.handleChange}
								error={formik.touched.website && Boolean(formik.errors.website)}
								helperText={formik.touched.website && formik.errors.website}
							/>

							<Autocomplete
								id="id_access"
								options={access}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_access", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_access}
								renderInput={(params) => (
									<TextField required {...params} label="Tipo acceso" />
								)}
							/>

							<Autocomplete
								id="id_host"
								options={host}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_host", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_host}
								renderInput={(params) => (
									<TextField {...params} label="Alojamiento" />
								)}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<Autocomplete
								id="id_partner"
								options={partnerOptions}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("id_partner", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.id_partner}
								renderInput={(params) => (
									<TextField {...params} label="Partner" />
								)}
							/>

							<Autocomplete
								id="maker"
								options={makerOptions}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("maker", value);
								}}
								getOptionLabel={(option: any) => option.name}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								value={formik.values.maker}
								renderInput={(params) => (
									<TextField {...params} label="Fabricante" />
								)}
							/>

							<TextField
								id="supplier"
								name="supplier"
								style={{ width: "35%", margin: "1rem 0 1rem 1rem"}}
								label="Número de proveedor"
								value={formik.values.supplier}
								onChange={formik.handleChange}
								error={
									formik.touched.supplier && Boolean(formik.errors.supplier)
								}
								helperText={formik.touched.supplier && formik.errors.supplier}
							/>             
						</div>
							
						<div style={{display: "flex", justifyContent: "space-between"}}>
						{ formik.values.item_type === 'Licencia' ? (
							<>
								<Autocomplete
									id="id_licence"
									options={licence}
									style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
									onChange={(e, value) => {
										formik.setFieldValue("id_licence", value);
									}}
									getOptionLabel={(option: any) => option.name}
									isOptionEqualToValue={(option: any, value: any) =>
										option.id === value.id
									}
									value={formik.values.id_licence}
									renderInput={(params) => (
										<TextField {...params} label="Tipo de licencia" />
									)}
								/>
								<TextField
									id="licence_number"
									name="licence_number"
									style={{ width: "35%", margin: "1rem 0"}}
									label="Número de licencia"
									value={formik.values.licence_number}
									onChange={formik.handleChange}
									error={
										formik.touched.licence_number &&
										Boolean(formik.errors.licence_number)
									}
									helperText={
										formik.touched.licence_number && formik.errors.licence_number
									}
								/>
								{ !props.edit ? (
									<TextField
										id="licenses_files"
										label="Contrato de suministro"
										style={{ width: "35%", margin: "1rem 0 1rem 1rem", flexGrow: "2"}}

										inputProps={{
											// multiple: true,
											accept:"application/pdf",
										}}
										InputLabelProps={{ shrink: true }}
										type={"file"}
										onChange={(event: any) => {
											handleUploadFiles(event.currentTarget.files[0], event);
										}}
										value={formik.values.licenses_files}
										error={formik.touched.licenses_files && Boolean(formik.errors.licenses_files)}
										helperText={formik.touched.licenses_files && formik.errors.licenses_files}
									/>
									) : <div style={{ width: "35%", margin: "1rem 0 1rem 1rem"}}></div> 
								}	
							</> ) : <></> }          
						</div>
						
						<div style={{display: "flex", justifyContent: "space-between"}}>
						
							<TextField
								type="number"
								id="accesses_number"
								name="accesses_number"
								style={{ width: "35%", margin: "1rem 1rem 1rem 0"}}
								InputProps={{
									inputProps: { min: 0 }
								}}
								label="Número de accesos"
								value={formik.values.accesses_number}
								onChange={formik.handleChange}
								error={
									formik.touched.accesses_number &&
									Boolean(formik.errors.accesses_number)
								}
								helperText={
									formik.touched.accesses_number && formik.errors.accesses_number
								}
							/>
							<TextField
								type="number"
								id="consumed_users"
								style={{ margin: "1rem 0", width: "35%"}}
								name="consumed_users"
								label="Accesos consumidos"
								value={formik.values.consumed_users}
								onChange={formik.handleChange}
								error={
									formik.touched.consumed_users &&
									Boolean(formik.errors.consumed_users)
								}
								helperText={
									formik.touched.consumed_users && formik.errors.consumed_users
								}
							/>   

							<Autocomplete
								id="confid"
								options={confidOptions}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("confid", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// defaultValue={formik.values.confid}
								renderInput={(params) => (
									<TextField {...params} label="Confidencialidad" />
								)}
							/>         
						</div>
						
						<div style={{display: "flex", justifyContent: "space-between"}}>
							
							<TextField
								type="date"
								InputLabelProps={{ shrink: true }}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								id="purchase_date"
								name="purchase_date"
								label="Fecha de compra"
								value={formik.values.purchase_date}
								onChange={formik.handleChange}
								error={
									formik.touched.purchase_date &&
									Boolean(formik.errors.purchase_date)
								}
								helperText={
									formik.touched.purchase_date && formik.errors.purchase_date
								}
							/>

							<TextField
								id="budget"
								name="budget"
								style={{ margin: "1rem 0", width: "35%"}}
								label="Partida presupuestaria"
								value={formik.values.budget}
								onChange={formik.handleChange}
								error={formik.touched.budget && Boolean(formik.errors.budget)}
								helperText={formik.touched.budget && formik.errors.budget}
							/>
							
							<TextField
								type="email"
								id="email"
								name="email"
								style={{ width: "35%", margin: "1rem 0 1rem 1rem", flexGrow: "2"}}
								label="Email"
								value={formik.values.email}
								onChange={formik.handleChange}
								error={formik.touched.email && Boolean(formik.errors.email)}
								helperText={formik.touched.email && formik.errors.email}
							/>
						</div>

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<Autocomplete
								id="crits"
								options={levelOptions}
								style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("crits", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.crits}
								renderInput={(params) => (
									<TextField {...params} label="Criticidad para negocio" />
								)}
							/>

							<Autocomplete
								id="integrity"
								options={levelOptions}
								style={{ margin: "1rem 0", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("integrity", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.integrity}
								renderInput={(params) => (
									<TextField {...params} label="Integridad" />
								)}
							/>

							<Autocomplete
								id="dispo"
								options={levelOptions}
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								onChange={(e, value) => {
									formik.setFieldValue("dispo", value);
								}}
								getOptionLabel={(option: any) => option.level}
								isOptionEqualToValue={(option: any, value: any) =>
									option.id === value.id
								}
								// value={formik.values.dispo}
								renderInput={(params) => (
									<TextField {...params} label="Disponibilidad" />
								)}
							/>
						</div>		

						<div style={{display: "flex", justifyContent: "space-between"}}>
							<TextField
									id="departmen"
									name="departmen"
									style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
									label="Departamento responsable de negocio"
									value={formik.values.departmen}
									onChange={formik.handleChange}
									error={formik.touched.departmen && Boolean(formik.errors.departmen)}
									helperText={formik.touched.departmen && formik.errors.departmen}
								/>

							<TextField
								id="product_own"
								name="product_own"
								style={{ margin: "1rem 0", width: "35%"}}
								label="Product Owner"
								value={formik.values.product_own}
								onChange={formik.handleChange}
								error={formik.touched.product_own && Boolean(formik.errors.product_own)}
								helperText={formik.touched.product_own && formik.errors.product_own}
							/>				

							<TextField
								id="buss_own"
								name="buss_own"
								style={{ margin: "1rem 0 1rem 1rem", width: "35%"}}
								label="Business Owner"
								value={formik.values.buss_own}
								onChange={formik.handleChange}
								error={formik.touched.buss_own && Boolean(formik.errors.buss_own)}
								helperText={formik.touched.buss_own && formik.errors.buss_own}
							/>
						</div>			

						<TextField        
							multiline
							rows={3}
							id="comment"
							name="comment"
							style={{ margin: "1rem 0"}}

							label="Comentario"
							value={formik.values.comment}
							onChange={formik.handleChange}
							error={formik.touched.comment && Boolean(formik.errors.comment)}
							helperText={formik.touched.comment && formik.errors.comment}
						/>

						{ formik.values.item_type === 'Licencia' ? (
							<FormControl id="status" style={{ minWidth: "15%", textAlign: "center", margin: "1rem 0 0"}}>
								<FormLabel style={{ fontSize: "0.8rem", textAlign:"start", color: "rgba(0, 0, 0, 0.6)" }}>Estado licencia</FormLabel>
								<FormGroup row onChange={formik.handleChange} style={{justifyContent: "start"}}>
									<FormControlLabel value={formik.values.status} control={<Checkbox id="is-active" checked={formik.values.status} onChange={handleActiveOptions}/>}  label="Activa" />
									<FormControlLabel value={!formik.values.status} control={<Checkbox id="is-inactive" checked={!formik.values.status} onChange={handleActiveOptions}/>}  label="Inactiva" />
								</FormGroup>
							</FormControl> 
						) : <></> }

					</>
					) : formik.values.item_type === 'Dominio' || formik.values.item_type === 'Certificado' ? (
						<>
							<div style={{display: "flex", justifyContent: "space-between"}}>				
								<TextField
									id="url"
									name="url"
									style={{ width: "30%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
									label="URL"
									value={formik.values.url}
									onChange={formik.handleChange}
									error={formik.touched.url && Boolean(formik.errors.url)}
									helperText={formik.touched.url && formik.errors.url}
								/>	

								<TextField
									id="hosting"
									name="hosting"
									style={{ width: "30%", margin: "1rem 0", flexGrow: "2"}}
									label="Hosting"
									value={formik.values.hosting}
									onChange={formik.handleChange}
									error={formik.touched.hosting && Boolean(formik.errors.hosting)}
									helperText={formik.touched.hosting && formik.errors.hosting}
								/>	

								{ formik.values.item_type === 'Certificado' ? (
									<TextField
										id="type"
										name="type"
										style={{ width: "30%", margin: "1rem 0 1rem 1rem", flexGrow: "2"}}
										label="Tipo de certificado"
										value={formik.values.type}
										onChange={formik.handleChange}
										error={formik.touched.type && Boolean(formik.errors.type)}
										helperText={formik.touched.type && formik.errors.type}
									/>	
								) : <></> }

							</div>

							<div style={{display: "flex", justifyContent: "space-between"}}>
								{ formik.values.item_type === 'Dominio' ? (
									<>
										<TextField
											type="date"
											InputLabelProps={{ shrink: true }}
											style={{ margin: "1rem 1rem 1rem 0", width: "35%"}}
											id="emit"
											name="emit"
											label="Emisión"
											value={formik.values.emit}
											onChange={formik.handleChange}
											error={
												formik.touched.emit &&
												Boolean(formik.errors.emit)
											}
											helperText={
												formik.touched.emit && formik.errors.emit
											}
										/>

										<TextField
											id="cost_re"
											name="cost_re"
											type='number'
											style={{ width: "35%", margin: "1rem 1rem 1rem 0", flexGrow: "2"}}
											label="Coste renovación"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">€</InputAdornment>
												),
												inputProps: { min: 0 }
											}}
											value={formik.values.cost_re}
											onChange={formik.handleChange}
											error={formik.touched.cost_re && Boolean(formik.errors.cost_re)}
											helperText={formik.touched.cost_re && formik.errors.cost_re}
										/>
									</>
								) : <></> }	

								<FormControlLabel
									control={
										<Checkbox
											value={formik.values.auto_re}
											checked={formik.values.auto_re}
											onChange={formik.handleChange}
										/>
									}
									label="Autorenovación"
									id="auto_re"
									name="auto_re"
								/>	
							</div>		
						</> ) : <></>}	

					{ formik.values.item_type != null ? (
						<>
							<div style={{display: "flex", justifyContent: "space-between"}}>
								<TextField
									type="date"
									id="expiration_day"
									name="expiration_day"
									style={{ margin: "2rem 1rem 1rem 0", width: "50%"}}
									InputLabelProps={{ shrink: true }}
									label="Fecha de vencimiento"
									value={formik.values.expiration_day}
									onChange={formik.handleChange}
									error={
										formik.touched.expiration_day &&
										Boolean(formik.errors.expiration_day)
									}
									helperText={
										formik.touched.expiration_day && formik.errors.expiration_day
									}
								/>
								<TextField
									id="advise_days"
									name="advise_days"
									type='number'
									style={{ width: "50%", margin: "2rem 0 1rem"}}
									label="Días para aviso de caducidad"
									InputProps={{
										inputProps: { min: 0 }
									}}
									value={formik.values.advise_days}
									onChange={formik.handleChange}
									error={formik.touched.advise_days && Boolean(formik.errors.advise_days)}
									helperText={formik.touched.advise_days && formik.errors.advise_days}
								/>
							</div>

							<div className="d-flex justify-content-center mt-3">
								<LoadingButton
									loading={IsLoadingButtom}
									style={{
										color: IsLoadingButtom ? "var(--blue-lis)" : "white",
										backgroundColor: "var(--blue-lis)",
									}}
									type="submit"
								>
									{!selectedRow ? "Crear" : "Guardar"}
								</LoadingButton>
							</div>
						</>
					) : <></> }
    		</Box>
			</div>
		</div>

		</>
  )
}
