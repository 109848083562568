import * as React from "react";
import moment from "moment";

import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";

import CustomModal from "../../Shared/ModalCustom/CustomModal";
import AedasLoading from "../../Shared/AedasLoading/AedasLoading";
import InventoryElementView from "./InventoryElementView";

import { getAllInventory } from "../../../Service/InventoryService/InventoryService";
import { getAllLicenceTypeService } from "../../../Service/LicenceTypeService/LicenceTypeService";
import { getAllHost } from "../../../Service/HostService/HostService";
import { getAllAccessType } from "../../../Service/AccessTypeService/AccessTypeService";
import { getAllParther } from "../../../Service/PartherService/PartherService";

import "./inventory.css";

function Inventory(props: any) {
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalView, setOpenModalView] = React.useState(false);

  const [allItems, setAllItems] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);
  const [columns, setColumns] = React.useState<any>([]);
  const [host, setHost] = React.useState<any>(null);
  const [licence, setLicence] = React.useState<any>(null);
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [access, setAccess] = React.useState<any>(null);
  const [partner, setPartner] = React.useState<any>(null);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (isLoading) {
      getAllInventory().then((response: any) => {
        setAllItems(response.data);
      });
      getAllHost().then((response: any) => {
        setHost(response.data);
      });
      getAllLicenceTypeService().then((response: any) => {
        setLicence(response.data);
      });
      getAllAccessType().then((response: any) => {
        setAccess(response.data);
      });
      getAllParther().then((response: any) => {
        setPartner(response.data);
      });

      setColumns(licenceColumns)
    }
  }, [isLoading]);

  React.useEffect(() => {
    if ( !isLoading ) {
      if ( props.itemType === 'activo') {
        setData(allItems.filter((item: any) => item.activo === true));
        setColumns(activesColumns);
      } else {
        setData(allItems.filter((item: any) => item.req_licence === true));
        setColumns(licenceColumns);
      }
    }
  }, [isLoading, props.itemType])


  React.useEffect(() => {
    if (selectionModel.length > 0) {
      setSelectedRow(data.find((d: any) => d.id === selectionModel[0]));
    }
  }, [selectionModel]);

  React.useEffect(() => {
    if (!openModalView) {
      setSelectedRow(null);
    }
  }, [openModalView]);

  React.useEffect(() => {
    if (selectedRow != null) {
      setOpenModalView(true);
    }
  }, [selectedRow]);

  React.useEffect(() => {
    if (allItems && licence && host && partner) {
      setIsLoading(false);
    }
  }, [licence, allItems, host, partner]);

  const licenceColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "app_name",
      headerName: "Aplicación",
      width: 300,
      flex: 2,
    },
    {
      field: "id_licence",
      headerName: "Tipo de Licencia",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{licence.find((h: any) => h.id === params.value)?.name}</>;
      },
    },
    {
      field: "id_host",
      headerName: "Host",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{host.find((h: any) => h.id === params.value)?.name}</>;
      },
    },
    {
      field: "id_partner",
      headerName: "Partner",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <div className="MuiDataGrid-cellContent">{partner.find((h: any) => h.id === params.value)?.name}</div>;
      },
    },
    {
      field: "expiration_day",
      headerName: "Fecha de vencimiento",
      width: 300,
      flex: 1,
      renderCell(params) {
        if (params.value === "" || !params.value) {
          return <>Sin vencimiento</>;
        }
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
    },
  ];

  const activesColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "app_name",
      headerName: "Nombre del activo",
      width: 300,
      flex: 2,
    },
    {
      field: "departmen",
      headerName: " Dpto. Resp. de negocio",
      width: 300,
      flex: 2,
    },
    {
      field: "buss_own",
      headerName: "Business Owner",
      width: 300,
      flex: 1,
    },
    {
      field: "product_own",
      headerName: "Product Owner",
      width: 300,
      flex: 1,
    },
    {
      field: "id_partner",
      headerName: "Partner",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <div className="MuiDataGrid-cellContent">{partner.find((h: any) => h.id === params.value)?.name}</div>;
      },
    },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-5' id='home'>
        <CustomModal
          size="xl"
          show={openModalView}
          onChange={() => {
            setOpenModalView(!openModalView);
          }}
          title={`Información ${props.itemType === 'licencia' ? 'de la Licencia' : 'del Activo Tecnológico' }`}
          body={
            <InventoryElementView
              selectedRow={selectedRow}
              setOpenModal={setOpenModal}
              setOpenModalView={setOpenModalView}
              setIsLoading={setIsLoading}
              itemType={props.itemType}
            />
          }
        />

        <h4 className='main-title mb-3 mt-0'>{ props.itemType === 'licencia' ? 'Licencias' : 'Activos Tecnológicos' }</h4>

        <div
          style={{
            background: "white",
            borderRadius: "5px",
            paddingTop: "2px",
          }}
        >
          <div className="tableContainer">
            <DataGrid
              rows={data}
              columns={columns}
              rowsPerPageOptions={[10, 20, 50, 100]}
              pagination
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
