import { useEffect, useState } from 'react';
import moment from "moment";
import { useFetch, useIsLoading } from '../../Hooks/useFetch';

import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";

import AedasLoading from "../../Components/Shared/AedasLoading/AedasLoading";
import { getAllDomains, updateDomainById, deleteDomains } from "../../Service/InventoryService/InventoryService"; 

import { ListEditItems } from '../../Components/ListEditItems/ListEditItems';

import "../../Components/Static/Iventory/inventory.css";

export const Domains = () => {
  const [ domains, isFetchingDomains, updateDomains ] = useFetch(getAllDomains);
  
  const isLoading = useIsLoading([isFetchingDomains]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "url",
      headerName: "URL",
      width: 300,
      flex: 2,
      editable: true,
    },
    {
      field: "hosting",
      headerName: "Hosting",
      width: 300,
      flex: 1,
      editable: true,
    },
    {
      field: "emit",
      headerName: "Emisión",
      width: 300,
      flex: 1,
      renderCell(params) {
        if (params.value === "" || !params.value) {
          return <>Sin vencimiento</>;
        }
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
      editable: true,
    },
    {
      field: "expiration_day",
      headerName: "Fecha de vencimiento",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{moment(params.value).format("DD/MM/YYYY")}</>;
      },
      editable: true,
    },
    {
      field: "cost_re",
      headerName: "Coste de renovación",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{ params.value } €</>;
      },
      editable: true,
    },
    {
      field: "auto_re",
      headerName: "Autorenovación",
      width: 300,
      flex: 1,
      renderCell(params) {
        return <>{ params.value ? 'Si' : 'No'}</>;
      },
      editable: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className='h-100 m-4'>
      <div className='main-container p-5' id='home'>
        <h4 className='main-title mb-3 mt-0'>Dominios </h4>

        <ListEditItems 
          rows={domains} 
          columns={columns}
          serviceUpdate={updateDomainById}
          serviceDelete={deleteDomains}
          itemType={'dominio'}
          update={() => updateDomains()}
        />
  </div>
    </div>
  )
}
