import { GridColDef, gridRowsLookupSelector } from "@mui/x-data-grid";
import React, { useState } from "react";
import TableEdit from "./TableEdit/TableEdit";
import TableNormal from "./TableNormal/TableNormal";

function DataGridEditabled(props: any) {
  const { rows, columns, title } = props;
  const [edit, setEdit] = useState<boolean>(false);
  console.log("columnas", columns);
  return (
    <>
      {!edit ? (
        <TableNormal
          onCellClick={props.onCellClick}
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          title={title}
          rowsPerPageOptions={props.rowsPerPageOptions}
        ></TableNormal>
      ) : (
        <TableEdit
          onlyEdit={props.onlyEdit}
          handleSave={props.handleSave}
          checkSave={props.checkSave}
          rows={rows}
          columns={columns}
          setEdit={setEdit}
          title={title}
          handleAdd={props.handleAdd}
          rowsPerPageOptions={props.rowsPerPageOptions}
        ></TableEdit>
      )}
    </>
  );
}

export default DataGridEditabled;
