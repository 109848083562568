import { useState } from 'react';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import DataGridEditabled from "../Shared/DataGridEditabled/DataGridEditabled";
import AedasLoading from "../Shared/AedasLoading/AedasLoading";

import "../../Components/Static/Iventory/inventory.css";

export const ListEditItems = (props: any) => {
  console.log(props)
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
 
  const handleAdd = (item: any) => {
    history.push(`/nuevo/${props.itemType}`)
  };

  const handleUpdate = (items: any) => {
    let promises: any = [];
    
    items.map((item: any) => {
      promises.push(props.serviceUpdate(item));
    });
    
    Promise.all(promises)
      .then(() => {
        setIsLoading(true);
        toast.success("Guardado");
      })
      .then(() => props.update());
  };

  const handleRemove = (items: any) => {
    let promises: any = [];
    
    items.map((item: any) => {
      promises.push(props.serviceDelete(item));
    });

    Promise.all(promises)
      .then(() => {
        setIsLoading(true);
        toast.success("Borrado");
      })
      .then(() => props.update());
  };


  const handleSave = (data: any) => {
    if (data.edited.length > 0) {
      handleUpdate(data.edited);
    }

    if (data.removed.length > 0) {
      handleRemove(data.removed);
    }
  };


  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <div className="tableContainer">
      <DataGridEditabled 
        rows={props.rows}
        columns={props.columns}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        pagination
        handleSave={handleSave}
        autoHeight={true}
        handleAdd={handleAdd}
      />
    </div>
  )
}
