import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef, GridToolbarContainer } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import "./TableNormal.css";

function TableNormal(props: any) {
  const { rows, columns, setEdit } = props;
  const [columsCustom, setColumsCustom] = useState<GridColDef[]>([]);
  const [columsRows, setRowsCustom] = useState([]);

  useEffect(() => {
    processData();
  }, [rows]);

  const processData = () => {
    setRowsCustom(rows);
    let array: GridColDef[] = [];

    let columsAux = JSON.parse(JSON.stringify(columns));
    console.log(columsAux);
    columsAux.map((row: GridColDef, i: number) => {
      row.editable = false;

      if (columns[i].renderCell) {
        row.renderCell = columns[i].renderCell;
      }

      if (columns[i].renderEditCell) {
        row.renderEditCell = columns[i].renderEditCell;
      }

      array.push(row);
    });

    array.sort((a, b) => {
        return parseInt(a.field) < parseInt(b.field) ? -1 : 1
    })

    setColumsCustom(array);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="d-flex justify-content-between">
        <h1 className="titleTableNormal">{props.title}</h1>
        <IconButton onClick={handleEdit}>
          <EditIcon></EditIcon>
        </IconButton>
      </GridToolbarContainer>
    );
  };
  console.log("columsCustom", columsCustom);
  return (
    <div style={{ height: "100%", background: "white" }}>
      <DataGrid
        onCellClick={props.onCellClick}
        components={{ Toolbar: CustomToolbar }}
        rows={columsRows}
        columns={columsCustom}
        disableSelectionOnClick
        autoHeight={true}
        rowsPerPageOptions={props.rowsPerPageOptions}
      ></DataGrid>
    </div>
  );
}

export default TableNormal;
