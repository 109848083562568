import axios from "axios";
import Partner from "../../Components/Static/Partner/Partner";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const addItemToInventory = (inventory: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/inventory/",
    inventory,getDefaultConfigHeader()
  );
};

export const getAllInventory = () => {
  return axios.get(process.env.REACT_APP_API_BACKEND + "/inventory/",getDefaultConfigHeader());
};

export const getInventoryItem = (params: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + params.id,getDefaultConfigHeader() );
};

export const updateInventory = (inventory: any, id: number) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + id,
    inventory,getDefaultConfigHeader()
  );
};

export const deleteInventory = (inventory: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/inventory/" + inventory.id,getDefaultConfigHeader()
  );
};

export const getAllCertificates = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/certificate/",getDefaultConfigHeader()
  );
};

export const addItemToCertificates = (body: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/certificate/",
    body,getDefaultConfigHeader()
  );
};

export const updateCertificateById = (body: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/certificate/" + body.id,
    body,getDefaultConfigHeader()
  );
};

export const deleteCertificate = (body: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/certificate/" + body.id,
    getDefaultConfigHeader()
  );
};


export const getAllDomains = () => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + "/domain/",
    getDefaultConfigHeader()
  );
};

export const addItemToDomains = (body: any) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND + "/domain/",
    body,getDefaultConfigHeader()
  );
};

export const updateDomainById = (body: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/domain/" + body.id,
    body,getDefaultConfigHeader()
  );
};

export const deleteDomains = (body: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/domain/" + body.id,getDefaultConfigHeader()
  );
};