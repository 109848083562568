import * as React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const login = async (email: string, password: string) => {
  const response: any = await axios
    .post(process.env.REACT_APP_API_BACKEND + "/login", {
      email: email,
      password: password,
    })
    .catch((error) => {
      if (error.request.status === 401) {
        toast.error("Usuario o contraseña incorrecto");
      } else {
        toast.error("Ha habido un problema, intentelo más tarde");
      }
    });

  if (!response) {
    return null;
  }

  localStorage.setItem("aedas_user", JSON.stringify(response.data));
  return JSON.stringify(response.data);
};
export const loginAzure = async (token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios.get(
    process.env.REACT_APP_API_BACKEND + "/auth/loginAzure",
    config
  );
};
