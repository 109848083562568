import React, { useEffect, useState, useRef } from "react";
import Login from "./Components/Static/Login/Login";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsightsIcon from '@mui/icons-material/Insights';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import HomeExample from "./Components/Static/Home/HomeExample";
import HomeIcon from "@mui/icons-material/Home";
import { toast, ToastContainer } from "react-toastify";
import Header from "./Components/Shared/Header/Header";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal, MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { CustomNavigationClient } from "./Utils/NavigationClient";
import { loginAzure } from "./Service/AuthService/AuthService";
import { userLogin } from "./Redux/User/UserAction";
import AedasLoading from "./Components/Shared/AedasLoading/AedasLoading";
import InventoryIcon from "@mui/icons-material/Inventory";
import Partner from "./Components/Static/Partner/Partner";
import Inventory from "./Components/Static/Iventory/inventory";
import { Certificates } from "./Pages/Certificates/Certificates";
import { Domains } from "./Pages/Domains/Domains";
import { DigitalActives } from "./Pages/DigitalActives/DigitalActives";
import { NewItem } from "./Pages/NewItem/NewItem";
type AppProps = {
  pca: IPublicClientApplication;
};
function App({ pca }: AppProps) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <>
      <ToastContainer autoClose={1500}></ToastContainer>
      <MsalProvider instance={pca}>
        <Router></Router>
      </MsalProvider>
    </>
  );
}

export default App;

const Router = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const isLogin = useSelector((state: any) => state.userReducer.isLogin);
  const user = useSelector((state: any) => state.userReducer.user);

  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      setIsLoading(false);
      history.push(location.pathname);
    }
  }, [isLogin]);

  useEffect(() => {
    if (isAuthenticated && inProgress === "none") {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then(async (response: any) => {
          const responseBackend: any = await loginAzure(
            response.accessToken
          ).catch((error: any) => console.log("ERROR"));
          if (responseBackend) {
            dispatch(
              userLogin(
                responseBackend.data,
                response.accessToken,
                responseBackend.data.employee.azure_rol
              )
            );
           localStorage.setItem(
              "aedas_user",
              JSON.stringify(responseBackend.data)
            );
          } else {
            toast.error("No tienes permiso");
            history.push("/login");
            setIsLoading(false);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    } else if (!isAuthenticated && inProgress === "none") {
      instance
        .loginRedirect(loginRequest)
        .then((response: any) => console.log("login", response))
        .catch((e: any) => {
          console.error(e);
        });
    } else if (inProgress === "none") {
      setIsLoading(false);
    }
  }, [isAuthenticated, inProgress]);

  const menu: any = [
    {
      name: "Licencias",
      localPath: "/",
      icon: <InventoryIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Licencias",
      hasPermissions: true,
    },
    {
      name: "Activos tecnológicos",
      localPath: "/activos-tecnologicos",
      icon: <InsightsIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Act. Tec.",
      hasPermissions: true,
    },
    {
      name: "Dominios",
      localPath: "/dominios",
      icon: <TravelExploreIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Dominios",
      hasPermissions: true,
    },
    {
      name: "Certificados",
      localPath: "/certificados",
      icon: <VerifiedUserIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Cert.",
      hasPermissions: true,
    },
    {
      name: "Añadir",
      localPath: "/nuevo",
      icon: <ControlPointIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Añadir",
      hasPermissions: true,
    },
    {
      name: "Partner / Fabricante",
      localPath: "/partner",
      icon: <HandshakeIcon />,
      onClick: history.push,
      canView: true,
      shortName: "Part. / Fabr.",
      hasPermissions: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center">
        <AedasLoading></AedasLoading>
      </div>
    );
  }

  return (
    <>
      <div className="row" style={{ height: "100vh" }}>
        {isLogin ? (
          <Header menu={menu} open={isOpen} setIsOpen={setIsOpen}></Header>
        ) : (
          <></>
        )}

        <div
          style={
            isLogin ? { flex: 1, marginLeft: "70px" } : {}
          }
          className="w-100"
        >
          <Switch>
            <Route exact path="/login">
              <Login isLogin={isLogin}></Login>
            </Route>
            
            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/">
              <Inventory itemType={'licencia'}/>
            </PrivateRoute>
            

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/activos-tecnologicos">
              <Inventory itemType={'activo'}/>
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/dominios">
              <Domains />
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/certificados">
              <Certificates />
            </PrivateRoute>


            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/nuevo">
              <NewItem />
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/nuevo/dominio">
              <NewItem dominio={true}/>
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/nuevo/certificado">
              <NewItem certificado={true}/>
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/editar/activo/:id">
              <NewItem edit={true} />
            </PrivateRoute>

            <PrivateRoute auth={isLogin} hasPermissions={true} exact path="/editar/licencia/:id">
              <NewItem edit={true}/>
            </PrivateRoute>



            <PrivateRoute
              auth={isLogin}
              hasPermissions={true}
              exact
              path="/partner"
            >
              <Partner></Partner>
            </PrivateRoute>


          </Switch>
        </div>
      </div>
    </>
  );
};
const PrivateRoute = (props: any) => {
  if (props.auth) {
    if (props.hasPermissions) {
      return <Route {...props}></Route>;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <Redirect to="/login" />;
  }
};
