import axios from "axios";
import Partner from "../../Components/Static/Partner/Partner";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const addParther = (partner: any) => {
  return axios.post(process.env.REACT_APP_API_BACKEND + "/partner/", partner,  getDefaultConfigHeader());
};

export const getAllParther = () => {
  return axios.get(process.env.REACT_APP_API_BACKEND + "/partner/",  getDefaultConfigHeader());
};

export const updateParther = (partner: any) => {
  return axios.put(
    process.env.REACT_APP_API_BACKEND + "/partner/" + partner.id,
    partner,  getDefaultConfigHeader()
  );
};

export const deleteParther = (partner: any) => {
  return axios.delete(
    process.env.REACT_APP_API_BACKEND + "/partner/" + partner.id,  getDefaultConfigHeader()
  );
};
